import {Collection} from '~/gql/generated';
import {useExpertLink} from './useExpertLink';

export const useCollectionLink = (
  collection: Collection,
  useFullDomain?: boolean,
) => {
  const base = useExpertLink('/', useFullDomain);
  if (!collection) return '';
  const isBrand = collection?.id?.startsWith('BRAND_');

  return `${base}${
    isBrand
      ? `brand/${encodeURIComponent(collection.slug)}`
      : `collections/${encodeURIComponent(collection.slug)}/${collection.id}`
  }`;
};

export const useBrandCollectionLink = (
  slug: string,
  useFullDomain?: boolean,
) => {
  return useExpertLink(`/brand/${slug}`, useFullDomain);
};
